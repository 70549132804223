<template>
    <div>
      <el-button type="primary" @click="open()">打开选择框</el-button>
      <div>我是数据
      <p>{{dynamicTags}}</p></div>

      <!--来款拆分                        -----------------开始------------->
      <div class="incomingSplit">
        <ul class="incomingSplitTitle">
          <li>来款类型</li>
          <li>所属客户</li>
          <li>来款序列号</li>
          <li>金额</li>
        </ul>
        <ul class="incomingSplitData">
          <li>待拆来款</li>
          <li>{{datas.laikuanList.subordinate}}</li>
          <li>{{datas.laikuanList.index}}</li>
          <li>{{datas.laikuanList.money}}</li>
        </ul>
        <ul class="incomingSplitData">
          <li>拆做认单</li>
          <li>{{datas.rendanList.subordinate}}</li>
          <li>{{datas.rendanList.index}}</li>
          <li><el-input v-model="datas.rendanList.money" @input="computeMoney(datas.rendanList.money,'rendan')"></el-input>
            <span>输入认单款可自动计算留存款</span></li>
        </ul>
        <ul class="incomingSplitData">
          <li>拆做留存</li>
          <li>
            <el-select v-model="datas.liucunList.subordinate" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>{{datas.liucunList.index}}</li>
          <li><el-input v-model="datas.liucunList.money" @input="computeMoney(datas.liucunList.money,'liucun')"></el-input>
            <span>输入留存款可自动计算认单款</span></li>
        </ul>
      </div>
      <!--来款拆分                        -----------------end------------->
      <el-dialog
        ref=""
        width="700px"
        id="choose"

        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="设置显示项目"
        :visible.sync="dialogheaderVisible"
      >
        <chooseBox :dynamic-tags="dynamicTags" :choose-lists="chooseLists"></chooseBox>
        <div
          class="marginTop30"
          style="text-align: center"
        >
          <el-button
            type="primary"
            @click="saveChoose()"
          >
            确认
          </el-button>
          <el-button @click="dialogheaderVisible = false">
            取消
          </el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
  import chooseBox from "@/views/demo/chooseBox/chooseBox.vue";
  // <!--来款拆分                        -----------------开始------------->
  import {floatSub} from "@/util/util"
  // <!--来款拆分                        -----------------end------------->
  export default {
    name: "chooseBoxDemo",
    data(){
      return{
        // <!--来款拆分                        -----------------开始------------->
        options: [{
          value: '1',
          label: '泰来煤炭（上海）有限公司'
        }, {
          value: '2',
          label: '泰来煤炭（上海）有限公司1'
        }, {
          value: '3',
          label: '泰来煤炭（上海）有限公司2'
        }, {
          value: '4',
          label: '泰来煤炭（上海）有限公司3'
        }, {
          value: '5',
          label: '泰来煤炭（上海）有限公司4'
        }],
        datas: {
          laikuanList:{
            subordinate:"泰来煤炭（上海）有限公司",
            index:"190221090",
            money:2000.42
          },
          rendanList:{
            subordinate:"泰来煤炭（上海）有限公司",
            index:"190221090",
            money:null
          },
          liucunList:{
            subordinate:"1",
            index:"保存后生成",
            money:null
          },
        },
        // <!--来款拆分                        -----------------end------------->


        dialogheaderVisible:false,
        dynamicTags: [{ index: 0, chooseList: { i: 0, name: "雇员姓名" } }, {
          index: 4,
          chooseList: { i: 0, name: "雇员姓名" }
        }, { index: 4, chooseList: { i: 1, name: "雇员姓名41" } }],
        chooseLists: [
          { index: 0, type: "雇员信息", chooseList: [{ i: 1, name: "雇员信息" }] },
          { index: 1, type: "职位", chooseList: [{ i: 0, name: "雇员姓名1" }, { i: 1, name: "雇员信息1" }] },
          { index: 2, type: "雇员信息2", chooseList: [{ i: 0, name: "雇员姓名2" }, { i: 1, name: "雇员信息2" }] },
          { index: 3, type: "雇员信息3", chooseList: [{ i: 0, name: "雇员姓名3" }, { i: 1, name: "雇员信息3" }] },
          { index: 3, type: "雇员信息3", chooseList: [] }
        ]
      }
    },
    components: {chooseBox},
    methods:{
    // <!--来款拆分                        -----------------开始------------->
      computeMoney(val,type){
        const temp = val;
        if(val === ""){
          val = "0"
        }
        // 限制两位小数数字
        val = val.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
        val = val.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
        val = val.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
        val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数
        if(val.indexOf(".")< 0){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
          val= parseFloat(val);
        }else if(val.indexOf(".")> 1 && val[0] === "0"){//控制0X.XX
          val= parseFloat(val);
        }else if(val.indexOf(".") === 0 ){//控制.XX
          val= parseFloat("0"+val);
        }

        if(val <= this.datas.laikuanList.money){
          if(type === "rendan"){
            this.datas.rendanList.money = val;
            this.datas.liucunList.money = floatSub(this.datas.laikuanList.money,[val]);
          }else{
            this.datas.rendanList.money = floatSub(this.datas.laikuanList.money,[val]);
            this.datas.liucunList.money = val;
          }
        }else{
          if(type === "rendan"){
            this.datas.rendanList.money = this.datas.laikuanList.money;
            this.datas.liucunList.money = 0;
          }else{
            this.datas.rendanList.money = 0;
            this.datas.liucunList.money = this.datas.laikuanList.money;
          }
        }

      },
    // <!--来款拆分                        -----------------end------------->
      open (){
        this.dialogheaderVisible = true
      },
      saveChoose(){
        this.dialogheaderVisible = false
      }
    }
  };

</script>

<style scoped>


</style>
