<template>
  <div class="chooseBox">
    <div class="chooseLeft">
      <div class="chooseTitle">
        <p>可选项目</p>
        <div class="chooseIcon">
          <el-tooltip class="item" effect="dark" content="可选项目" placement="top">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tishi"></use>
            </svg>
          </el-tooltip>
        </div>
      </div>
      <div class="chooseCollapse">
        <el-scrollbar>
          <el-collapse v-model="activeNames">
            <el-collapse-item :title="item.type" :name="idx" v-for="(item,idx) in chooseLists" v-bind:key="item.index">
              <div class="chooseLi" v-for="(data,io) in item.chooseList" v-bind:key="data.i"
                   @click="chooseData(idx,io)">
                {{data.name}}
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-scrollbar>
      </div>
    </div>
    <div class="chooseRight">
      <div class="chooseTitle">
        <p>展示信息项目</p>
        <div class="chooseIcon">
          <el-tooltip class="item" effect="dark" content="展示信息项目" placement="top">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tishi"></use>
            </svg>
          </el-tooltip>
        </div>
      </div>
      <div class="chooseDatas">
        <draggable :list="dynamicTags" group="item" @change="log">
          <el-tag
            :key="tag"
            v-for="tag in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{tag.chooseList.name}}
          </el-tag>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  export default {
    name: "chooseBox",
    components: {
      draggable
    },
    props:{
      dynamicTags:{
        type: Array,
        default () {
          return []
        }
      },
      chooseLists:{
        type: Array,
        default () {
          return []
        }
      },
    },
    watch:{
      dynamicTags: function (newVal, oldVal) {
        this.$emit("update:dynamicTags",true);
      },
      chooseLists: function (newVal, oldVal) {
        this.$emit("update:chooseLists",true);
      },
    },
    data() {
      return {
        activeNames: [],
      };
    },
    methods: {
      chooseData(index, i) {
        let obj = {};
        obj.index = index;
        obj.chooseList = this.chooseLists[index].chooseList[i];
        this.dynamicTags.push(obj);
        this.chooseLists[index].chooseList.splice(i, 1);
      },
      handleClose(tag) {
        this.chooseLists[tag.index].chooseList.push(tag.chooseList);
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      },
      log(evt) {
        console.log(this.dynamicTags)
      }
    }
  };
</script>

<style scoped>
</style>
